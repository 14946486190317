// helper function to filter undefined/null and their types, useful mostly for filtering arrays
export function notUndefinedOrNull<T>(x: T | undefined | null): x is T {
  return x !== undefined && x !== null;
}

export interface ElementSize {
  width: number;
  height: number;
}

export function detectElementSize(element: HTMLElement): ElementSize {
  const width = element.offsetWidth;
  const height = element.offsetHeight;
  return {
    width,
    height,
  };
}

export function getUrlAndParams(): { url: URL; params: URLSearchParams } {
  const url = new URL(window.location.toString());
  const params = new URLSearchParams(window.location.search);
  return { url, params };
}

export function getStudioPlayerVersion(): string {
  return (
    process.env.REACT_APP_VERSION || process.env.REACT_APP_GITHASH || "N/A"
  );
}
/**
 * convert data from data value table in to query string
 * @param dataValue
 * @returns queryString
 */
export function dataValueToQueryString(
  dataValue: { [key: string]: string } | undefined
): string | null {
  const queryString = new URLSearchParams(dataValue);
  return queryString.toString();
}

/**
 * parsing url with params and extra paams from data value table
 * @param url a full url string
 * @param queryStrings[] a list of queryStrings
 * @returns finalURL
 */
export function parsingURLWithQueryString(
  url: string,
  queryStrings: (string | null)[]
): string {
  const splitHash = url.indexOf("#") > -1 ? url.split("#") : url;
  const hashesParams = Array.isArray(splitHash)
    ? splitHash
        .slice(1)
        .map((hash) => `#${hash}`)
        .join("")
    : "";
  const urlWithoutHash = Array.isArray(splitHash)
    ? splitHash.shift()
    : splitHash;
  const urlQueryParams =
    urlWithoutHash && urlWithoutHash?.indexOf("?") > -1
      ? urlWithoutHash?.split("?")
      : "";
  const pureURL = Array.isArray(urlQueryParams)
    ? urlQueryParams[0]
    : urlWithoutHash ?? url;

  // a final combine query params
  const finalQueryStr = [urlQueryParams[1], ...queryStrings]
    .filter((item) => item)
    .join("&");

  // a final url with query string and hash (if available)
  const finalURL =
    pureURL + (finalQueryStr !== "" ? "?" + finalQueryStr : "") + hashesParams;
  return finalURL;
}

// ref: https://www.notion.so/screencloud/Secure-Media-Content-9aec239e935b4761a97395c5a749dff9#01c75ad8da6b467688b56bc7bce8380e
export function ignoreToCacheTheseParamsOnOurNativePlayers(
  params: string
): string {
  const parsedParams = new URLSearchParams(params);
  const keys = parsedParams.keys();
  const keyArray = Array.from(keys);

  if (keyArray.length > 0) {
    const ignoreParam = "&sc_ignore_params=" + keyArray.join(",");
    return params + ignoreParam;
  }

  return params;
}
